import logo from './assets/Logotype_Black.svg';
import PerfumeImg from './components/PerfumeImg';
import PerfumeNotes from './components/PerfumeNotes';
import Header from './components/Header';
import Footer from './components/Footer';
import NotFound404 from './components/NotFound404';
import './main.scss';
import {useEffect, useState} from 'react';
import Loader from "./components/Loader";
import TmcCustomPage from './customPages/Tmc/tmc'
import VolvoCustomPage from "./customPages/Volvo/VolvoCustomPage";

function App() {
    const [pageURL,] = useState(window.location.href.split('/'));
    const [order, setOrder] = useState({
        perfumeName: "",
        perfumeMadeBy: "",
        created: "",
        perfumeNotes: [],
        perfumeImg: []
    });
    const [blendInfo, setBlendInfo] = useState({});
    const [invalidUrl, setInvalidUrl] = useState(false);

    useEffect(() => {
        async function getBlends() {
            try {
                const response = await fetch(
                    'https://no-ordinary-scent-api.herokuapp.com/v1/get-all-accord-index'
                );
                return response.json();
            } catch (e) {
                console.error("Error getting accords index")
            }
        }

        const fetchData = async (pageURL) => {

            const response = await fetch(`https://no-ordinary-scent-api.herokuapp.com/v1/get_order/${pageURL[3]}/${pageURL[4]}`)
            if (!response.ok) {
                setInvalidUrl(true)
                return;
            }
            const json = await response.json()
            setOrder({
                perfumeName: json.perfumeName,
                perfumeMadeBy: json.madeBy,
                created: json.createdAt,
                perfumeNotes: json.recipe,
                perfumeImg: json.imageUrls
            });
        }

        if (pageURL[3] && pageURL[4]) {
            fetchData(pageURL);
        } else {
            setInvalidUrl(true);
            return
        }
        getBlends().then((data) => {
            setBlendInfo(Object.assign(data.base, data.accord));
        }).catch(err => console.error("Error getting blends: ", err));
    }, [pageURL])

    return (
        pageURL[3] === 'tmc' ?
            <>
                <TmcCustomPage/>
                {Object.keys(blendInfo).length > 0 && <PerfumeNotes
                    perfumeName={order.perfumeName}
                    madeBy={order.perfumeMadeBy}
                    created={order.created}
                    top={blendInfo[order.perfumeNotes[0]]?.topDisplayName}
                    heart={blendInfo[order.perfumeNotes[0]]?.heartDisplayName}
                    base={blendInfo[order.perfumeNotes[1]]?.baseDisplayName}
                    blendInfo={blendInfo}
                />}
            </>
            : pageURL[3] === 'volvo' ?
            <>
                <VolvoCustomPage/>
            </>
            :
            <div className="App">
                <Header
                    logo={logo}
                />
                {order.perfumeName ?
                    <>
                        {Object.keys(blendInfo).length > 0 && <PerfumeNotes
                            perfumeName={order.perfumeName}
                            madeBy={order.perfumeMadeBy}
                            created={order.created}
                            top={blendInfo[order.perfumeNotes[0]]?.topDisplayName}
                            heart={blendInfo[order.perfumeNotes[0]]?.heartDisplayName}
                            base={blendInfo[order.perfumeNotes[1]]?.baseDisplayName}
                            blendInfo={blendInfo}
                        />}
                        <PerfumeImg
                            perfumeImg={order.perfumeImg}
                            perfumeNotes={order.perfumeNotes}
                        />
                        <Footer
                            perfumeName={order.perfumeName}
                            perfumeMadeBy={order.perfumeMadeBy}
                            recipe={order.perfumeNotes}
                            qrAccess={pageURL[4]}
                            images={order.perfumeImg}
                            orderNr={pageURL[3]}
                        />
                    </>
                    :
                    !invalidUrl ?
                        <Loader/> : <NotFound404/>
                }
            </div>
    );
}

export default App;
