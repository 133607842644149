import "./tmc.scss";
import PerfumeImg from "../../components/PerfumeImg";

const TmcCustomPage = () => {
    return (
        <>
            <div className="tmc-header">
                <img className="tmc-header-logo" src={require("./NOS_EMOTIONTECH_WHITE.png")}
                     alt="NOS Emotiontech logo"/>
                <img className="tmc-header-logo" src={require("./tmc-logo.png")} alt="NOS Emotiontech logo"/>
                <p className="header-h3">The Member Company</p>
            </div>
            <PerfumeImg
                perfumeImg={[require("./Skärmavbild 2024-11-14 kl. 11.06.35.png"), require("./Skärmavbild 2024-11-14 kl. 11.07.16.png"), require("./Skärmavbild 2024-11-14 kl. 11.07.30.png")]}
                perfumeNotes={['B1, A5']}/>

            <div className="parfume-info">
                <div className="perfume-info-container">
                    <div className="perfume-scentnotes-container">
                        <div className="perfume-scentnotes">
                            <h1 className="header-h1">THE MEMBER COMPANY IDENTITY FACTORS</h1>
                        </div>
                        <div className="perfume-scentnotes">
                            <p className="perfume-scentnotes-p">Core words</p>
                            <p className="perfume-scentnotes-h3">BrightMind, Forward thinking, Execution</p>
                        </div>
                        <div className="perfume-scentnotes">
                            <p className="perfume-scentnotes-p">Emotions</p>
                            <p className="perfume-scentnotes-h3">Passionate, Edgy, Playful, Curious</p>
                        </div>
                        <div className="perfume-scentnotes">
                            <p className="perfume-scentnotes-p">Colors</p>
                            <p className="perfume-scentnotes-h3">Blue, Black</p>
                        </div>
                        <div className="perfume-scentnotes">
                            <p className="perfume-scentnotes-p">Personality</p>
                            <p className="perfume-scentnotes-h3">Journey</p>
                        </div>
                        <br/>
                        <br/>
                        <img className="tmc-img" src={require("./IMG_0462_VSCO.jpg")} alt="TMC candle"/>
                        <br/>
                        <div className="perfume-scentnotes">
                            <h1 className="header-h1">SCENT COMPOSITION</h1>
                        </div>
                        <div className="perfume-scentnotes">
                            <p className="perfume-scentnotes-p">Top notes</p>
                            <p className="perfume-scentnotes-h3">Black Pepper, Milk Foam</p>
                        </div>
                        <div className="perfume-scentnotes">
                            <p className="perfume-scentnotes-p">Heart notes</p>
                            <p className="perfume-scentnotes-h3">Creamy Sandalwood, Burnt Incense</p>
                        </div>
                        <div className="perfume-scentnotes">
                            <p className="perfume-scentnotes-p">Base notes</p>
                            <p className="perfume-scentnotes-h3">Vetiver, Sparking Grapefruit, Cedarwood Atlas,
                                Amber</p>
                        </div>
                    </div>
                </div>
                <h3 className="tmc-tag-heading">TAGS</h3>
                <div className="tmc-tags-container">
                    <span className="tmc-tag">DREAMING</span>
                    <span className="tmc-tag">CURIOUS</span>
                    <span className="tmc-tag">LIGHTNESS</span>
                    <span className="tmc-tag">HAPPINESS</span>
                    <span className="tmc-tag">VIBRANT</span>
                </div>

                <br/>
                <br/>
                <div className="tmc-download-container">
                    <a className="tmc-download" href={require('./TMC_SCENTBRIEF2025.pdf')} download>Download Scent Brief</a>
                </div>
                <br/>
                <div className="tmc-footer">
                    <div className="footer">
                        <h1>ABOUT THE SCENT</h1>
                        <p>
                            NOS creates scents for brands through Emotiontech™, an AI-based technology enabling
                            targeted olfactory experiences that resonate with customers, predicting and evoking emotions
                            aligned with their preferences, forging a deeper and more meaningful connection between
                            brands and their audience.</p>
                        <br/>
                        <p><a className="footer-link"
                              href="mailto: hello@nosemotiontech.com">hello@nosemotiontech.com</a></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TmcCustomPage;
